'use client'

import { useEffect, useRef } from 'react'

import { useSectionThemeContext } from '@/contexts/SectionTheme.context'

import { motion, stagger, useAnimate } from 'framer-motion'
import { gsap } from 'gsap'

import * as S from './VariableText.styles'

// const transition = { duration: 2.4, ease: [0.43, 0.13, 0.23, 0.96] }

const fontRange = {
  weight: {
    min: 100,
    max: 500,
  },
  slant: {
    min: 0,
    max: -10,
  },
}

const useLettersAnimation = () => {
  const [scope, animate] = useAnimate()

  useEffect(() => {
    animate(
      '.text-letter',
      {
        opacity: [0, 1],
        filter: ['blur(10px)', 'blur(0px)'],
      },
      {
        duration: 1,
        delay: stagger(0.2, { startDelay: 0.15 }),
      }
    )
  }, [])

  return scope
}

const VariableText = () => {
  const textRef = useRef(null)
  const scope = useLettersAnimation()

  const colorScheme = useSectionThemeContext()

  useEffect(() => {
    const rootCSS = document.querySelector(':root')
    rootCSS.style.setProperty('--variable-text-wght', 200)
  }, [])

  useEffect(() => {
    const mouseMoveFunc = (e) => {
      const xPercent = e.offsetX / window.innerWidth
      const yPercent = e.offsetY / window.innerHeight

      const weight =
        xPercent * (fontRange.weight.max - fontRange.weight.min) +
        fontRange.weight.min
      const slant =
        yPercent * (fontRange.slant.max - fontRange.slant.min) +
        fontRange.slant.min

      // console.log(`weight: ${weight}, slant: ${slant}`)

      gsap.to(textRef.current, {
        duration: 0.6,
        '--variable-text-wght': weight,
        '--variable-text-slant': slant,
        ease: 'power3',
        overwrite: true,
      })
    }

    document.addEventListener('mousemove', mouseMoveFunc)
    document.addEventListener('touchmove', mouseMoveFunc)

    return () => {
      document.removeEventListener('mousemove', mouseMoveFunc)
      document.removeEventListener('touchmove', mouseMoveFunc)
    }
  }, [])

  return (
    <S.Wrapper colorScheme={colorScheme}>
      <S.TextWrapper
        as={motion.div}
        ref={scope}
        initial={{
          fontVariationSettings: '"wght" 700',
          opacity: 0,
        }}
        animate={{
          fontVariationSettings: '"wght" 200',
          opacity: 1,
        }}
      >
        <S.Text ref={textRef}>
          <span className="text-letter">s</span>
          <span className="text-letter">t</span>
          <span className="text-letter">f</span>
          <span className="text-letter">n</span>
        </S.Text>
      </S.TextWrapper>
    </S.Wrapper>
  )
}

export default VariableText
