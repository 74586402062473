import styled from 'styled-components'
import { primaryFontVariable } from '@/theme/fonts'

export const TextWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.mediaQuery.md(`
    width: auto;
  `)}
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${primaryFontVariable.style}
  font-size: 100px;
  line-height: 1;
  font-optical-sizing: auto;
  font-variation-settings: 'wght' var(--variable-text-wght), 'slnt' var(--variable-text-slant);
  text-align: center;

  ${({ theme }) => theme.mediaQuery.sm(`
    display: flex;
    flex-direction: row;
    font-size: 200px;
  `)}

  ${({ theme }) => theme.mediaQuery.lg(`
    font-size: 350px;
  `)}

  span {
    &:not(:last-child) {
      margin-right: 0;
      
      ${({ theme }) => theme.mediaQuery.md(`
        margin-right: 50px;
      `)}
    }
  }
`

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100svh;
  position: relative;
  background-color: ${({ theme, colorScheme }) => theme.colors[colorScheme].surface.default};

  ${Text} {
    color: ${({ theme, colorScheme }) => theme.colors[colorScheme].surface.dark1};
  }
`
